<script>
import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";

export default {
  page: {
    title: "Widgets",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Widgets",
    };
  },
  name: "Widgets",
  components: {
    Layout,
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card rounded-0 bg-soft-success mx-n4 mt-n4 border-top">
          <div class="px-4">
            <div class="row">
              <div class="col-xxl-5 align-self-center">
                <div class="py-4">
                  <h4 class="display-6 coming-soon-text">
                    Terms & Conditions
                  </h4>
                </div>
              </div>
              <div class="col-xxl-3 ms-auto">
                <div class="mb-n5 pb-1 faq-img d-none d-xxl-block">
                  <img
                    src="@/assets/images/faq-img.png"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->

        <div class="row justify-content-evenly h5" style="line-height:2;">
        
<p>These terms and conditions (&ldquo;Terms&rdquo;) govern the use of services made available on or through https://www.e-triq.com and/or the E-Triq mobile app (collectively, the &ldquo;Platform&rdquo;, and together with the services made available on or through the Platform, the &ldquo;Services&rdquo;). These Terms also include our privacy policy, available at https://www.e-triq.com/privacy-policy (&ldquo;Privacy Policy&rdquo;), and any guidelines, additional, or supplemental terms, policies, and disclaimers made available or issued by us from time to time (&ldquo;Supplemental Terms&rdquo;). The Privacy Policy and the Supplemental Terms form an integral part of these Terms. In the event of a conflict between these Terms and the Supplemental Terms with respect to applicable Services, the Supplemental Terms will prevail.</p>


<p>The Terms constitute a binding and enforceable legal contract between E-Triq Tax Ventures Private Limited (a company incorporated under the Companies Act, 2013 with its registered address at B-357/A, Kh. No. 389/263/1, First Floor, New Ashok Nagar, Delhi-110096 and its affiliates (&ldquo;UC&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;our&rdquo;), and you, a user of the Services, or any legal entity that books Pro Services (defined below) on behalf of end-users (&ldquo;you&rdquo; or &ldquo;Customer&rdquo;). By using the Services, you represent and warrant that you have full legal capacity and authority to agree to and bind yourself to these Terms. If you represent any other person, you confirm and represent that you have the necessary power and authority to bind such person to these Terms.</p>


<p>By using the Services, you agree that you have read, understood, and are bound by, these Terms, as amended from time to time, and that you will comply with the requirements listed here. These Terms expressly supersede any prior written agreements with you. If you do not agree to these Terms, or comply with the requirements listed here, please do not use the Services.</p>

<p>1. SERVICES</p>


<p>(a) The Services include the provision of the Platform that enables you to select the service from the range of services given. As a part of the Services, E-Triq facilitates the receipt of data and documents from you and then arranges to process the data and documents to perform and render the selected service. E-Triq collects the payments on its own behalf.</p>

<p>(b) E-Triq is not responsible to check the authenticity and ownership of the data and documents shared over the platform.</p>

<p>(c) The Platform is for your own business use only, unless otherwise agreed upon on in accordance with the terms of a separate agreement. Please note that the Platform is intended for use only within India and UAE.&nbsp;</p>

<p>(d) A key part of the Services is E-Triq&rsquo;s ability to send you text messages, electronic mails, chat option or WhatsApp messages, including in connection with your bookings, your utilisation of the Services, or as a part of its promotional and marketing strategies.</p>

<p>(e) In certain instances, you may be required to furnish identification proof to avail the Services, and hereby agree to do so. A failure to comply with this request may result in your inability to use the Services.</p>

<hr>

<p>2. ACCOUNT CREATION</p>


<p>(a) To avail the Services, you will be required to create an account on the Platform (&ldquo;Account&rdquo;). For this Account, you may be required to furnish certain details, including but not limited to your phone number and email. To create an Account, you must be at least 18 years of age.</p>

<p>(b) You warrant that all information furnished in connection with your Account is and shall remain accurate and true. You agree to promptly update your details on the Platform in the event of any change to or modification of this information.</p>

<p>(c) You are solely responsible for maintaining the security and confidentiality of your Account and agree to immediately notify us of any disclosure or unauthorised use of your Account or any other breach of security with respect to your Account.</p>

<p>(d) You are liable and accountable for all activities that take place through your Account, including activities performed by persons other than you. We shall not be liable for any unauthorised access to your Account.</p>

<p>(e) You agree to receive communications from us regarding (i) requests for payments, (ii) information about us and the Services, (iii) promotional offers and services from us and our third party partners, and (iv) Any other matter in relation to the Services.</p>

<hr>
<p>4. CONSENT TO USE DATA</p>



<p>(a) You agree that we may, in accordance with our Privacy Policy, collect and use your personal and business related data and information. The Privacy Policy is available at <a href="https://www.e-triq.com/privacy-policy">https://www.e-triq.com/privacy-policy</a> and it explains the categories of information that we collect or otherwise process about you and the manner in which we process such data.</p>

<p>(b) In addition to any consent you may provide pursuant to the Privacy Policy, you hereby consent to us sharing your information with our affiliates. We may use information and data pertaining to your use of the Services for provision of the Services, analytics, trend identification, and purposes of statistics to further enhance the effectiveness and efficiency of our Services, and provision of beneficial schemes, new offers, and for experience enhancement.</p>

<p>(c) Subject to applicable laws, we may be directed by law enforcement agencies or the government and related bodies to disclose data in relation to you in connection with criminal or civil proceedings. You understand and agree that in such instances we shall have the right to share such data with relevant agencies or bodies.</p>

<hr>

<p>5. BOOKINGS</p>



<p>(a) Orders: The Platform permits you to request various Services at a time. To make a booking, you should follow the instructions on the Platform and provide necessary information. We use reasonable efforts to process the given information within 2 days and ask for any further information/ clarification required to enable us to perform/render the selected service.</p>

<p>(b) Confirmation: Once you place a request we will provide confirmation of the booking via SMS, email or a push notification. Once your booking has been confirmed, you will be required to make the payment in accordance with these Terms or as indicated on the Platform.</p>

<p>(c) Cancellations: Bookings that are required to be cancelled needs to be communicated via email dropped at the given email ID. No cancellations would be entertained after 7 days of booking or after 2 days of receipt of required information to perform the service, whichever is earlier.</p>

<p>(d) Substitution: In case of the cancellation of a selected Service, we will offer you a substitute of the Service from among our available service.</p>



<hr>

<p>6. PRICING, FEES, AND PAYMENT TERMS</p>



<p>(a) E-Triq reserves the right to charge you for the different Services you may avail and/or for any other facilities you may opt for, from time to time, on or via the Platform.</p>



<p>(b) E-Triqs hall notify you of the applicable Charges, Fees, and payment methods at the time of booking. Generally, you may make payments for Pro Services through credit cards, debit cards, net banking, wallets or UPI. We have the right to modify and otherwise restrict the modes of payment available to you. You acknowledge that certain payment methods may not always be available to you as a payment method.</p>

<p>(c) The charges and Fees shall be payable at the time of making a booking as specified by E-Triq.</p>

<p>(d) Taxes: All Charges and Fees are inclusive of applicable taxes, if any.</p>

<p>(e) E-Triq reserves the right to reasonably amend the Charges and Fees at any time at its sole discretion. A change in Fees shall not impact any bookings that have been confirmed before the publication of the revised Fees on the Platform.</p>

<p>(f) Charges and Fees that you pay are final and non-refundable, unless otherwise determined by E-Triq or required by the applicable laws. Under certain laws, you may be entitled to a refund or other remedies for a failure in the provision of the Services.</p>

<p>(g) Payment Processors: We may use a third-party payment processor (&ldquo;Payment Processor&rdquo;) to bill you through your selected mode of payment. The processing of payments will be subject to the terms and policies of such Payment Processor in addition to these Terms. We shall not be liable for any error of the Payment Processor. In the event of any unsuccessful payment, the money debited shall be credited in accordance with the terms of the Payment Processor.</p>

<p>(h) All the payments towards statutory dues including but not limited to goods and service tax, income tax, any fees under companies Act have to be made directly by you and its due processing is solely your responsibility.</p>






<hr>
<p>7. THIRD PARTY SERVICES</p>



<p>(a) The Platform include services, content, documents, and information owned by, licensed to, or otherwise made available by, a third party (&ldquo;Third Party Services&rdquo;) and contain links to Third Party Services. You understand and acknowledge that Third Party Services are the sole responsibility of the third party that created or provided it and that use of such Third Party Services is solely at your own risk.</p>

<p>(b) We make no representations and exclude all warranties and liabilities arising out of or pertaining to such Third Party Services, including their accuracy or completeness. Should you avail a Third Party Service, you shall be governed and bound by the terms and conditions and privacy policy of the third parties providing the Third Party Services. Further, all intellectual property rights in and to Third Party</p>





<p>are the property of the respective third parties.</p>


<hr>


<p>8. YOUR RESPONSIBILITIES</p>



<p>(a) You represent and warrant that all information that you provide in relation to the Services is complete, true, and correct while you avail the Services. Should any information that you provide change during the existence of these Terms, you undertake to immediately bring such change to our notice. We do not accept any responsibility or liability for any loss or damage that you may suffer or incur if any information, documentation, material, or data, provided to avail the Services is incorrect, incomplete, inaccurate, or misleading or if you fail to disclose any material fact.</p>

<p>(b) You shall extend all cooperation to us in our defence of any proceedings that may be initiated against us due to a breach of your obligations or covenants under these Terms.</p>

<p>(c) You represent and warrant that:</p>

<p>(i) you own all intellectual property rights (or have obtained all necessary permissions) to provide the information and to grant the licences under these Terms;</p>

<p>(ii) you are solely responsible for all activities that occur on or through your account on the Platform;</p>

<p>(iii) the information shared does not and shall not contain any viruses, corrupted data, or other harmful, disruptive, or destructive files or content;</p>

<p>(iv) the information shared does not and shall not violate any third party rights; and</p>

<p>(v) the information shared (A) does not belong to any other person to which you do not have any right, (B) does not threaten the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, public order, cause incitement to the commission of any cognisable offence, prevents investigation of any offence, or is insulting another nation, (C) is not defamatory, grossly harmful, blasphemous, paedophilic, invasive of another&rsquo;s privacy, discriminatory based on gender, ethnically objectionable, disparaging, relating to, or encouraging money laundering or gambling, libellous, hateful, racist, violent, obscene, pornographic, unlawful, harmful to children, or (D) otherwise offensive, objectionable, or restricts, or inhibits, any other person from using or enjoying the Services.</p>

<p>(d) You shall not use the Services in any manner except as expressly permitted in these Terms. Without limiting the generality of the preceding sentence, you shall not:</p>

<p>(i) infringe any proprietary rights, including but not limited to copyrights, patents, trademarks, or trade secrets of any party;</p>

<p>(ii) except as may be provided hereunder, copy, display, distribute, modify, publish, reproduce, store, transmit, post, translate, create any derivative works from or license the Services;</p>

<p>(iii) use the Services to transmit any data, or send or upload any material that contains viruses, Trojan horses, worms, time bombs, keystroke loggers, spyware, adware, or any other harmful programmes, or similar computer code, designed to adversely affect the operation of any computer software or hardware;</p>

<p>(iv) use any robot, spider, other automated device, or manual process to monitor or copy the Services or any portion thereof;</p>

<p>(v) engage in the systematic retrieval of content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory;</p>

<p>(vi) use the Services in (A) any unlawful manner, (B) for fraudulent or malicious activities or (C) in any manner inconsistent with these Terms;</p>

<p>(vii) decompile, reverse engineer, or disassemble the Services;</p>

<p>(viii) link to, mirror, or frame, any portion of all or any of the Services; or</p>

<p>(ix) violate applicable laws in any manner.</p>

<p>(e) You warrant that you shall not engage in any activity that interferes with or disrupts the Services.</p>

<p>(f) You shall not attempt to gain unauthorised access to any portion or feature of the Services, any other systems or networks connected to the Services, to any of our servers, or through the Platform by hacking, password mining, or any other illegitimate means.</p>




<hr>
<p>9. OUR INTELLECTUAL PROPERTY</p>



<p>(a) All rights, titles, and interest in, and to the Services, including all intellectual property rights arising out of the Services, are owned by or otherwise licensed to us. Subject to compliance with these Terms, we grant you a non-exclusive, non-transferable, non-sub licensable, revocable, and limited licence to use the Services in accordance with these Terms and our written instructions issued from time to time. Any rights not expressly granted herein are reserved by E-Triq.</p>

<p>(b) We may request you to submit suggestions and other feedback, including bug reports, relating to the Services from time to time (&ldquo;Feedback&rdquo;). We may freely use, copy, disclose, publish, display, distribute, and exploit the Feedback we receive from you without any payment of royalty, acknowledgement, prior consent, or any other form of restriction arising out of your intellectual property rights.</p>

<p>(c) Except as expressly stated in these Terms, nothing in these Terms should be construed as conferring any right in, or licence to, our or any third party&rsquo;s intellectual property rights.</p>




<hr>
<p>10. TERM AND TERMINATION</p>



<p>(a) These Terms shall remain in effect unless terminated in accordance with the terms hereunder.</p>

<p>(b) We may restrict, deactivate, or terminate your access to, or use of, the Services, or any portion thereof, (i) immediately and at any point at our sole discretion, (A) if you violate or breach any of the obligations, responsibilities, or covenants under these Terms, (B) when you cease to become a user of our Services, (C) you do not, or are likely not to qualify under applicable law, or the standards and policies of E-Triq or its affiliates, to access and use the Services, or (D) violate or breach the Community Guidelines, (ii) upon 30 (Thirty) days&rsquo; prior written notice to you, or (iii) immediately for any legitimate business, legal, or regulatory reason.</p>

<p>(c) You may terminate these Terms, at any time, for any reason by sending a notice to E-Triq at given email ID.</p>

<p>(d)</p>

<p>Upon termination of these Terms:</p>

<p>(i) the Account will expire;</p>

<p>(ii) the Services will &ldquo;time-out&rdquo;; and</p>

<p>(iii) these Terms shall terminate, except for those clauses that are expressly, or by implication, intended to survive termination or expiry.</p>




<hr>
<p>11. DISCLAIMERS AND WARRANTIES</p>



<p>(a) The Services are provided on an &ldquo;as is&rdquo; basis without warranty of any kind, express, implied, statutory or otherwise, including without limitation the implied warranties of title, non-infringement, merchantability, or fitness for a particular purpose. Without limiting the foregoing, we make no warranty that the Services will meet your requirements or expectations.</p>

<p>(b) No advice or information, whether oral or written, obtained by you from us shall create any warranty that is not expressly stated in the Terms.</p>

<p>(c) While E-Triq strives to provide accurate information about Services and Charges, pricing errors may occur from time to time.</p>

<p>(d) You hereby accept full responsibility for any consequences that may arise from your use of the Services, and expressly agree and acknowledge that we shall have absolutely no liability in this regard.</p>

<p>(e) You hereby accept to provide the information as asked from time to time within the time frame as per the process flow given on the platform and accept that any delay from your side may lead to consequences like delay in filing statutory forms leading thereby to imposition of interest, fines and/ or penalties by the Government</p>

<p>(f) E-Triq will maintain a complaints management framework and will manage this framework in a reasonable manner and in accordance with the non-excludable requirements of relevant applicable laws.</p>

<p>(g) To the fullest extent permissible by law, we, our affiliates, and our related parties, each disclaim all liability for any loss or damage arising out of, or due to:</p>

<p>(i) your use of, inability to use, or availability or unavailability of the Services</p>

<p>(ii) the occurrence or existence of any defect, interruption, or delays, in the operation or transmission of information to, from, or through the Services, communications failure, theft, destruction, or unauthorised access to our records, programmes, services, servers, or other infrastructure relating to the Services;</p>

<p>(iii) the failure of the Services to remain operational for any period of time; and</p>

<p>(iv) the loss of any data in connection with your use of the Services.</p>

<p>(h) In no event shall E-Triq, its officers, directors, and employees, or its contractors, agents, licensors, partners, or suppliers, be liable to you for any direct, special, indirect, incidental, consequential, punitive, reliance, or exemplary damages (including without limitation, lost business opportunities, lost revenues, or loss of anticipated profits or any other pecuniary or non-pecuniary loss or damage of any nature whatsoever, including but not limited to any abuse or breach of data), even if E-Triq or an authorised representative had been advised of the possibility of such damages, arising out of, or relating to (A) these Terms, (B) the Services, (C) your use or inability to use the Services , or (D) any other interactions with another user of the Services.</p>

<p>(i) To the maximum extent permitted by law, our liability shall be limited to the amount of fees we receive in respect of a particular booking made on the Platform. In no event shall our total liability to you in connection with these Terms exceed INR 10,000 (Rupees Ten Thousand).</p>

<p>(j) Nothing in these Terms will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit.</p>










<hr>
<p>12. INDEMNITY</p>



<p>You shall indemnify, defend at our option, and hold us, our parent companies, subsidiaries, affiliates, and our officers, employees, directors, agents, and representatives, harmless from and against any claim, demand, lawsuits, judicial proceeding, losses, liabilities, damages, and costs(including, without limitation, all damages, liabilities, settlements, and attorneys&rsquo; fees), due to or arising out of your access to the Services, use of the Services, violation of these Terms, or any violation of these Terms by any third party who may use your Account.</p>





<p>14. JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION</p>



<p>(a) These Terms shall be governed by and construed and enforced in accordance with the laws of India. Subject to other provisions in this clause, courts in New Delhi shall have exclusive jurisdiction over all issues arising out of these Terms or the use of the Services.</p>



<p>(b) Any controversies, conflicts, disputes, or differences, arising out of these Terms shall be resolved by arbitration in New Delhi in accordance with the Arbitration and Conciliation Act, 1996 for the time being in force, which is deemed to be incorporated by reference in this clause. The tribunal shall consist of 1 (One) arbitrator appointed by E-Triq. The language of the arbitration shall be English. The parties to the arbitration shall keep the arbitration confidential, and not disclose to any person, other than on a need to know basis, or to legal advisors, unless required to do so by law. The decision of the arbitrator shall be final and binding on all the parties thereto. Each party to the arbitration shall bear its own costs with respect to any dispute.</p>




<hr>
<p>13. GRIEVANCE REDRESSAL</p>



<p>(a) You may contact our designated Grievance Redressal Officer with any complaints or queries relating to the Services or these Terms through registered post or through email</p>



<p>(b) We shall ensure that your complaint is resolved within timelines prescribed by applicable laws.</p>










<hr>
<p>14. MISCELLANEOUS PROVISIONS</p>



<p>(a) Changes to Terms: The Terms are subject to revisions at any time, as determined by us, and all changes are effective immediately up on being posted on the Platform. It is your responsibility to review these Terms periodically for any updates or changes. You will be deemed to have accepted the changes made to these Terms if you continue to use the Platform once it has been posted.</p>

<p>(b) Modification to the Services: We reserve the right at any time to add, modify, or discontinue, temporarily or permanently, the Services (or any part thereof), with or without cause. We shall not be liable for any such addition, modification, suspension, or discontinuation of the Services.</p>

<p>(c) Severability: If any provision of these Terms is determined by any court or other competent authority to be unlawful or unenforceable, the other provisions of these Terms will continue to be in effect. If any unlawful or unenforceable provision would be lawful or enforceable if a part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect (unless that would contradict the clear intention of the clause, in which case the entirety of the relevant provision will be deemed to be deleted).</p>

<p>(d) Assignment: You shall not license, sell, transfer, or assign your rights, obligations, or covenants under these Terms, or your Account in any manner without our prior written consent. We may grant or withhold this consent at our sole discretion, subject to any conditions we deem appropriate. We may assign our rights to any of our affiliates, subsidiaries, or parent companies, any successor in interest of any business associated with the Services, or any third party without any prior notice to you.</p>

<p>(e) Notices: All notices, requests, demands, and determinations for us under these Terms (other than routine operational communications) shall be sent to legal@e-triq.com.</p>

<p>(f) Third Party Rights: No third party shall have any rights to enforce any terms contained herein.</p>

<p>(g) Force Majeure: We shall have no liability to you if we are prevented from or delayed in performing our obligations, or from carrying on our business, by acts, events, omissions, or accidents beyond our reasonable control, including without limitation, strikes, failure of a utility service or telecommunications network, act of God, war, riot, civil commotion, malicious damage, or compliance with any law or governmental order, rule, regulation, or direction.</p>






        </div>
      </div>
      <!--end col-->.
    </div>
    <!--end row-->
  </Layout>
</template>
